import { WidgetTokenInterface } from './widget-config.interface';

const tokenConfigs: WidgetTokenInterface[] = [
  {
    hosts: ['landgoedborkerheide.nl', 'boekenzuil.landgoedborkerheide.nl', 'landgoedborkerheide.local'],
    options: {
      usePin: true,
      theme: 'landgoedborkerheide',
      token: 'Borkerheide:a7be8e1fe282a37cd666e0632b17d933fa13f21addf4798fc0455bc166e2488c',
      colorLoader: '#9d2931',
      colorZoomBg: '#562b4c',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyCyN5t2dusGkKJHgMPoy8MNGVwvA8ZgPyQ',
      favicon: '/favicon-landgoedborkerheide.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Landgoed Borkerheide', website_url: 'www.landgoedborkerheide.nl', phone_number: '' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [3562],
      salesChannel: 10114,
      petsConfig: { articles: [81241, 81621], max: 2 },
      alternativeResults: true,
      useMarkerClusterer: false,
      allowNavigation: true,
    },
  },
  {
    hosts: ['vreehorst.nl', 'vreehorst.localhost', 'test.boekenzuil-vreehorst.3wstaging.nl', 'tommywizard.3wstaging.nl'],
    options: {
      usePin: false,
      theme: 'vreehorst',
      token: 'vreehorst:ce0d320a502ecee73ce06e640cad426ce1b955d3c5202ebc3f9d03df1ee7379b',
      colorLoader: '#a2c312',
      colorZoomBg: '#0083A1',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDLXXvOq9BJxt9btrZOtgiE9uV6TUUxDIg',
      favicon: '/favicon-vreehorst.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Vreehorst', website_url: 'www.vreehorst.nl', phone_number: '0543 514 805' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [2987, 2091],
      salesChannel: 9574,
      petsConfig: { articles: [48625], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {
        14337: 'price-no-reductionpass',
        14333: 'price-no-reductionpass',
        14335: 'price-no-reductionpass',
        19849: 'price-no-reductionpass',
      },
      allowNavigation: true,
    },
  },
  {
    hosts: [
      'test.11950.boekenzuil-vreehorst.3wstaging.nl',
      '11950.boekenzuil-vreehorst.3wstaging.nl',
      '11950.boekenzuil.vreehorst.nl',
      'vreehorst-11950.localhost',
    ],
    options: {
      usePin: true,
      theme: 'vreehorstcp',
      token: 'vreehorst:ce0d320a502ecee73ce06e640cad426ce1b955d3c5202ebc3f9d03df1ee7379b',
      colorLoader: '#a2c312',
      colorZoomBg: '#0083A1',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDLXXvOq9BJxt9btrZOtgiE9uV6TUUxDIg',
      favicon: '/favicon-vreehorst.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Vreehorst', website_url: 'www.vreehorst.nl', phone_number: '0543 514 805' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [2987, 2091],
      salesChannel: 11950,
      petsConfig: { articles: [48625], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {
        14337: 'price-no-reductionpass',
        14333: 'price-no-reductionpass',
        14335: 'price-no-reductionpass',
        19849: 'price-no-reductionpass',
      },
      allowNavigation: true,
    },
  },
  {
    hosts: ['reestland.3wstaging.nl', 'reestland.local'],
    options: {
      usePin: false,
      theme: 'reestland',
      token: 'reestland:556ea1b2f7420f2cd4e6d1d548f7389cdaf91535020c5917e16d2b3bf6b98844',
      colorLoader: '#a2c312',
      colorZoomBg: '#0083A1',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDknFIA662kSGtljapgIasq8ZXgXT3NidU',
      favicon: '/favicon-reestland.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Reestland', website_url: 'www.reestland.nl', phone_number: '' },
      travelGroupLimits: { '4093': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [2467, 2473, 6246, 6267],
      salesChannel: 13827,
      petsConfig: { articles: [62037, 62007], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: true,
      accommodationLabels: {},
      allowNavigation: true,
    },
  },
  {
    hosts: ['boekenzuil.campingkostverloren.nl', 'campingkostverloren.3wstaging.nl', 'kostverloren.local', 'node.php73.cms'],
    options: {
      usePin: true,
      theme: 'kostverloren',
      token: 'kostverloren:f06ca10f4977ea7a4857646c826cc9d4b5fa691e2c57766a98ed93dc6001ecde',
      colorLoader: '#f2fabb',
      colorZoomBg: '#7da74f',
      colorZoomFg: '#04380a',
      mapsKey: 'AIzaSyBNb7oHj8ZaBNlmFd42Ul9F0B6lfEz4sIA',
      favicon: '/favicon-kostverloren.ico',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Kostverloren', website_url: 'www.campingkostverloren.nl', phone_number: '' },
      travelGroupLimits: { '6736': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [5194],
      salesChannel: 13986,
      // salesChannel: 9040,
      petsConfig: { articles: [87772], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: true,
      accommodationLabels: {},
      allowNavigation: true,
    },
  },
  {
    hosts: ['boekenzuil.lutjekossink.nl', 'lutjekossink.3wstaging.nl', 'lutjekossink.local'],
    options: {
      usePin: false,
      theme: 'lutjekossink',
      token: 'lutjekossink:867067959260d3f3a0d605f9fcc328cc1d891fb6c13643edbf29163ca5e0ba70',
      colorLoader: '#f2fabb',
      colorZoomBg: '#c2d74c',
      colorZoomFg: '#8fa809',
      mapsKey: 'AIzaSyDxPZKrJrkcrN7Y2sGEStr6Wvj8BFNMlgg',
      favicon: '/favicon-lutjekossink.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping LutjeKossink', website_url: 'www.lutjekossink.nl', phone_number: '' },
      travelGroupLimits: { '10068': { min: 1, max: 6 }, global: { max: 12 } },
      maxDuration: 99,
      accommodationGroups: [6614, 6611],
      salesChannel: 14181,
      petsConfig: { articles: [156125, 155157], max: 2 },
      alternativeResults: false,
      useMarkerClusterer: true,
      accommodationLabels: {},
      allowNavigation: true,
    },
  },
  {
    hosts: ['uiterwaarde.local', 'test.boekenzuil-uiterwaarde.3wstaging.nl', 'boekenzuil-uiterwaarde.3wstaging.nl', 'localhost'],
    options: {
      usePin: true,
      theme: 'uiterwaarde',
      token: 'Uiterwaarde:994aa605e86d263f1818ca1cec63a70b694f492aa906560e90ca193ef4dc181d',
      colorLoader: '#002764',
      colorZoomBg: '#002764',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDLXXvOq9BJxt9btrZOtgiE9uV6TUUxDIg',
      favicon: '/favicon-uiterwaarde.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Uiterwaarde', website_url: 'www.uiterwaarde.nl', phone_number: '0344 – 68 16 44' },
      travelGroupLimits: { '8389': { min: 1, max: 1, default: 1 }, global: { max: 1 } },
      maxDuration: 99,
      accommodationGroups: [],
      salesChannel: undefined,
      petsConfig: { articles: [], max: 99 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
      allowNavigation: false,
      updateInitialDate: false,
      arrangementsWithLicensePlate2: ['213269', '251341', '293651'],
    },
  },
  {
    hosts: ['uiterwaardevr.local'],
    options: {
      usePin: true,
      theme: 'uiterwaarde',
      token: 'Uiterwaarde:994aa605e86d263f1818ca1cec63a70b694f492aa906560e90ca193ef4dc181d',
      colorLoader: '#002764',
      colorZoomBg: '#002764',
      colorZoomFg: '#ffffff',
      mapsKey: 'AIzaSyDLXXvOq9BJxt9btrZOtgiE9uV6TUUxDIg',
      favicon: '/favicon-uiterwaarde.png',
      entrypoints: ['start-booking' /*, 'start-reserved'*/],
      translations: { park_name: 'Camping Uiterwaarde', website_url: 'www.uiterwaarde.nl', phone_number: '0344 – 68 16 44' },
      travelGroupLimits: { '8389': { min: 1, max: 1, default: 1 }, global: { max: 1 } },
      maxDuration: 99,
      accommodationGroups: [],
      salesChannel: undefined,
      petsConfig: { articles: [], max: 99 },
      alternativeResults: false,
      useMarkerClusterer: false,
      accommodationLabels: {},
      allowNavigation: false,
      updateInitialDate: false,
      arrangementsWithLicensePlate2: ['179412', '251341', '293651'],
    },
  },
];

export default tokenConfigs;
